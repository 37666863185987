<template>
  <div class="settings">
    <van-field v-model="wifiName" :label="t('wifi_name')" />
    <van-field
      class="mt16"
      v-model="wifiPassword"
      :label="t('wifi_password')"
    />
    <van-button
      class="opbtn mt16"
      type="primary"
      :disabled="isDisconnected"
      @click="updateWifiConfiguration"
    >
      {{ t("update_wifi_configuration") }}
    </van-button>
    <van-button
      class="opbtn mt16"
      type="primary"
      :disabled="isDisconnected"
      @click="connectToWifi"
    >
      {{ t("connect_wifi") }}
    </van-button>
    <van-button
      class="opbtn mt16"
      type="primary"
      :disabled="isDisconnected"
      @click="getTheWifiStatus"
    >
      {{ t("wifi_status") }}
    </van-button>
    <van-button
      class="opbtn mt16"
      type="primary"
      :disabled="isDisconnected"
      @click="enterDownloadMode"
    >
      {{ t("enter_download_mode") }}
    </van-button>
  </div>
</template>

<style scoped>
.settings {
  padding: 16px;
}
.mt16 {
  margin-top: 16px;
}
.opbtn {
  width: 100%;
}
</style>

<script>
// @ is an alias to /src
import { t } from "@/utils/translate";
import { Toast, Dialog, Button, Field } from "vant";
import {
  cmdConfigWifi,
  cmdConnectWifi,
  cmdGetWifiStatus,
  cmdEnterDownloadMode,
} from "../utils/bluetoothhelper";

const testWifiConfig = (value) => {
  return /^[0-9a-zA-Z-_ ]{1,19}$/.test(value);
};

export default {
  name: "Settings",
  data() {
    return {
      wifiName: "",
      wifiPassword: "",
    };
  },
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
  },
  methods: {
    t(tag) {
      return t(tag);
    },
    async updateWifiConfiguration() {
      if (
        !testWifiConfig(this.wifiName) ||
        !testWifiConfig(this.wifiPassword)
      ) {
        Dialog.alert({ message: t("wifi_config_requirement") });
        return;
      }
      try {
        Toast.loading({
          message: t(`upload`),
          forbidClick: true,
          duration: 0,
        });
        await cmdConfigWifi(this.wifiName, this.wifiPassword);
        Toast.success(t("successed"));
      } catch (e) {
        console.error(e);
        Toast.fail(t("failed"));
      }
    },
    async connectToWifi() {
      try {
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });
        await cmdConnectWifi();
        Toast.clear(true);
        Dialog.alert({ message: t("wifi_is_connecting") });
      } catch (e) {
        console.error(e);
        Toast.fail(t("failed"));
      }
    },
    async getTheWifiStatus() {
      try {
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });
        let status = await cmdGetWifiStatus();
        let statusChar = status.substring(0, 1);
        let ip = status.substring(1);
        Toast.clear(true);
        if (statusChar === "0") {
          Dialog.alert({ message: t("wifi_not_connected") });
        } else if (statusChar === "1") {
          Dialog.alert({ message: t("wifi_is_connecting") });
        } else if (statusChar === "2") {
          Dialog.alert({ message: t("wifi_connected") + "\nIP: " + ip });
        } else if (statusChar === "a") {
          Dialog.alert({ message: t("wifi_status_wrong_password") });
        } else if (statusChar === "b") {
          Dialog.alert({ message: t("wifi_status_no_ap") });
        } else {
          Dialog.alert({ message: t("wifi_status_unknown") });
        }
      } catch (e) {
        console.error(e);
        Toast.fail(t("failed"));
      }
    },
    async enterDownloadMode() {
      try {
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });
        await cmdEnterDownloadMode();
        Toast.clear(true);
        Dialog.alert({ message: t("entered_download_mode") });
      } catch (e) {
        console.error(e);
        Toast.fail(t("failed"));
      }
    },
  },
  computed: {
    isDisconnected() {
      return this.$store.state.bleCharacteristic == undefined;
    },
  },
  async mounted() {
    this.$store.commit("navTitle", t("settings"));
    // let store = this.$store;
    // window["store"] = store;
  },
};
</script>
